<template>
  <section class="article" :theme="theme">
    <div class="article__header">
      <span @click="goBack()" class="article__back-btn">
        <Icon name="close" />
      </span>
    </div>


    <div class="article__body" v-if="article">
      <h1>{{ article.title }}</h1>
      <span class="article__updated">Mis à jour le {{ article.updated }}</span>

      <ShortcodeParser tag="div" class="article__body-content" :content="article.content" :theme="theme" />

      <footer v-if="(article.readmore && article.readmore.length > 0) || article.sources">
        <div v-if="article.readmore && article.readmore.length > 0">
          <h2>À lire ensuite</h2>
          <hr>
          <v-list class="article__readmore">
            <v-list-item class="article__readmore-item" tag="li" v-for="link in article.readmore" :key="link">
              <v-list-item-icon>
                <Icon name="file" />
              </v-list-item-icon>

              <v-list-item-content>
                <router-link :to="`/${$route.params.guide}/${link}`" class="article__readmore-item-link">
                  {{ articles[$route.params.guide][link].title }}
                </router-link>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>

        <div v-if="article.sources">
          <h2>Sources</h2>
          <hr>
          <div class="article__sources" v-html="article.sources"></div>
        </div>
      </footer>

      <span class="article__updated">Mis à jour le {{ article.updated }}</span>
    </div>

    <div v-else>
      <Message message="Page introuvable, si vous n'avez pas de réseau, essayez de repasser par la page d'accueil" type="error" class="centered" />
    </div>

    <v-btn elevation="2" fab class="search-switch">
      <router-link :to="`/${$route.params.guide}/recherche`">
        <Icon name="search" />
      </router-link>
    </v-btn>

    <ImagePopup />
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Article',

  props: {
    theme: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      prevUrl: null,
      useHistory: false,
    }
  },

  computed: mapState({
    articles: state => state.data.data.articles,
    article(state) {
      return state.data.data.articles[this.$route.params.guide][this.$route.params.slug]
    }
  }),

  methods: {
    goBack() {
      if (this.useHistory) {
        this.$router.go(-1)
      } else {
        this.$router.push(this.prevUrl)
      }
    }
  },

  beforeRouteEnter (to, from, next) {
    // Set prev url
    next(vm => {
      if (Object.keys(from.params).length > 0 && from.params.guide.length > 0) {
        // If user refresh the page keep the info
        if (from.path.includes(`${from.params.guide}/recherche`)) {
          window.sessionStorage.setItem('prevUrl', JSON.stringify({path: `/${from.params.guide}/recherche`, hash: from.hash}))
          vm.prevUrl = {path: `/${from.params.guide}/recherche`, hash: from.hash}
        } else {
          window.sessionStorage.setItem('prevUrl', JSON.stringify({path: `/${from.params.guide}`, hash: from.hash}))
          vm.prevUrl = {path: `/${from.params.guide}`, hash: from.hash}
        }
      } else if (window.sessionStorage.getItem('prevUrl')) {
        // If we have kept the info in session storage, set it in prev url
        vm.prevUrl = JSON.parse(window.sessionStorage.getItem('prevUrl'))
      } else {
        // If params is empty or we don't have guide info
        // set default prev url
        vm.prevUrl = {path: `/${to.params.guide}`}
      }
    })
  },

  beforeRouteUpdate (to, from, next) {
    if (Object.keys(from.params).length > 0 && from.params.guide.length > 0) {
      if (from.params.slug && from.params.slug.length > 0) {
        window.sessionStorage.setItem('prevUrl', '')
        this.prevUrl = ''
        this.useHistory = true
      } else {
        window.sessionStorage.setItem('prevUrl', JSON.stringify({path: `/${from.params.guide}`, hash: from.hash}))
        this.prevUrl = {path: `/${from.params.guide}`, hash: from.hash}
        this.useHistory = false
      }
    } else if (window.sessionStorage.getItem('prevUrl')) {
      // If we have kept the info in session storage, set it in prev url
      this.prevUrl = JSON.parse(window.sessionStorage.getItem('prevUrl'))
      this.useHistory = false
    } else {
      // If params is empty or we don't have guide info
      // set default prev url
      this.prevUrl = {path: `/${to.params.guide}`}
      this.useHistory = false
    }

    this.article = this.articles[to.params.guide][to.params.slug] ?? null
    next()
  },
}
</script>

<style lang="scss">
.article {
  &[theme="pediatrie"] {
    .article {
      &__back-btn {
        background-color: $purple;
      }

      &__body {
        h1,
        h3,
        h4,
        h5,
        .article__updated {
          color: $purple-dark;
        }

        h2 {
          color: $purple;
        }

        .table-wrapper {
          @media screen and (max-width: 767px) {
            &::before {
              color: rgba($purple-dark, 0.8);
            }
          }
        }

        table {
          td {
            border: 1px solid rgba($purple, 0.8);
          }
        }
      }
    }

    footer {
      .article__readmore {
        .article__readmore-item {
          color: rgba($purple-dark, 0.8) !important;
          transition: color 0.3s ease-in-out;

          &:hover {
            color: rgba($purple-dark, 1) !important;
          }

          &-link {
            color: currentColor;
          }
        }
      }
    }

    .search-switch {
      background-color: $purple-dark !important;
    }
  }

  &[theme="adulte"] {
    .article {
      &__back-btn {
        background-color: $blue-light;
      }

      &__body {
        h1,
        h3,
        h4,
        h5,
        .article__updated {
          color: $blue-dark;
        }

        h2 {
          color: $blue-light;
        }

        .table-wrapper {
          @media screen and (max-width: 767px) {
            &::before {
              color: rgba($blue-dark, 0.8);
            }
          }
        }

        table {
          td {
            border: 1px solid rgba($blue, 0.8);
          }
        }
      }
    }

    footer {
      .article__readmore {
        .article__readmore-item {
          color: rgba($blue-dark, 0.8) !important;
          transition: color 0.3s ease-in-out;

          &:hover {
            color: rgba($blue-dark, 1) !important;
          }

          &-link {
            color: currentColor;
          }
        }
      }
    }

    .search-switch {
      background-color: $blue-light !important;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px;
  }

  &__back-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    cursor: pointer;

    i.icon {
      color: $white;
    }
  }

  &__body {
    position: relative;
    display: block;
    padding: 0 20px;

    h1,
    h2,
    h3,
    h4,
    h5 {
      margin-bottom: 20px;
    }

    h1 {
      @include title(1);
    }

    h2 {
      @include title(2);
    }

    h3 {
      @include title(3);
    }

    h4 {
      @include title(4);
    }

    h5 {
      @include title(5);
    }

    ul {
      padding-left: 10px;
      margin-bottom: 40px;

      li {
        @include paragraph("normal");

        position: relative;
        padding-left: 18px;

        &::before {
          content: "•";
          position: absolute;
          top: 0;
          left: 0;
          font-weight: 700;
        }

        p {
          display: inline-block;
          margin-bottom: 0;
        }

        strong {
          font-weight: 700;
        }

        ul {
          padding-left: 18px;
          margin-bottom: 0;

          li {
            &::before {
              content: "-";
            }
          }
        }
      }
    }

    p {
      @include paragraph("normal");

      margin-bottom: 30px;

      &:empty {
        display: none;
      }

      strong {
        font-weight: 700;
      }

      em {
        font-style: italic;
      }
    }

    .table-wrapper {
      @media screen and (max-width: 767px) {
        position: relative;
        padding-top: 26px;
        overflow: hidden;

        &::before {
          content: 'Scrollez horizontalement →';
          position: absolute;
          top: 0;
          right: 0;
          display: inline-block;
          font-size: 11px;
          color: rgba($dark, 0.8);
          margin-bottom: 10px;
        }

        .table-inner {
          overflow: auto;
        }
      }
    }

    table {
      width: 100%;
      border-collapse: collapse;
      border-radius: 4px;

      td {
        padding: 8px;
        border: 1px solid rgba($dark, 0.8);
      }
    }

    .article__updated {
      display: block;
      font-size: 12px;
      margin-bottom: 40px;
      font-style: italic;

      &:last-child {
        margin: 20px 0;
      }
    }
  }

  footer {
    background: $grey-light;
    padding: 20px;
    margin: 20px 0;

    > div:not(:first-child) {
      margin-top: 40px;
    }

    .article__readmore {
      background: none;
      display: flex;
      flex-direction: column;
      padding: 20px 0;

      &-item {
        display: inline-flex;
        align-items: center;
        padding: 0 !important;
        margin-bottom: 26px !important;
        min-height: auto !important;

        &:last-child {
          margin-bottom: 0 !important;
        }

        .v-list-item__icon {
          display: block;
          font-size: 24px;
          line-height: 1em;
          width: 24px;
          height: 24px;
          margin-top: 0;
          margin-bottom: 0;
          margin-right: 12px !important;
        }

        .v-list-item__content {
          display: inline-flex;
          padding: 0;

          span {
            font-size: 14px;
            color: transparentize($dark, 0.5);

            ::v-deep strong {
              font-weight: 900;
              color: $dark;
            }
          }
        }

        &-link {
          display: inline-block;
          font-size: 20px;
          font-weight: 500;
          line-height: 1.2;
          letter-spacing: 0.15px;
          color: rgba($dark, 0.87);
        }
      }
    }

    .article__sources {
      padding: 20px 0;
    }

    .article__updated {
      margin-bottom: 0;
    }
  }
}
</style>
