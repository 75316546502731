<template>
  <v-dialog
      v-model="openPopup"
      fullscreen
      hide-overlay
      transition="scroll-x-transition"
      :content-class="`v-dialog-${image.orientation}`"
    >
      <div @click="openPopup = false" class="image-popup__close">
        <Icon name="close" />
      </div>

      <div class="image-popup__image">
        <img :src="image.src" :alt="image.alt">
      </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'ImagePopup',

  data () {
    return {
      openPopup: false,
      image: {
        src: '',
        alt: '',
        orientation: ''
      }
    }
  },

  mounted () {
    this.images = document.querySelectorAll('img')

    this.images.forEach(image => {
      image.addEventListener('click', () => {
        this.image.src = image.src
        this.image.alt = image.alt
        this.image.orientation = this.getOrientation(image)
        this.openPopup = true
      })
    })
  },

  methods: {
    getOrientation (el) {
      if (el.width > el.height) {
        return 'landscape'
      } else {
        return 'portrait'
      }
    }
  }
}
</script>

<style lang="scss">
.image-popup {
  &__close {
    position: fixed;
    top: 10px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    width: 50px !important;
    height: 50px !important;
    border-radius: 50px;
    background-color: $grey;
    z-index: 1;

    i.icon {
      color: $dark;
    }
  }

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.v-dialog-landscape {
  @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
    .image-popup__image {
      width: 100vw;
      height: 100vh;
    }
  }

  @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: portrait) {
    .image-popup__image {
      width: 100vh;
      height: 100vw;
    }
  }
}

.v-dialog-portrait {
  @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
    .image-popup__image {
      width: 100vh;
      height: 100vw;
    }
  }

  @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: portrait) {
    .image-popup__image {
      width: 100vw;
      height: 100vh;
    }
  }
}
</style>
