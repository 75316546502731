<template>
  <section  :theme="theme">
    <div class="header" ref="header">

      <v-app-bar class="header__wrapper" ref="headerWrapper" :color="theme == 'adulte' ? 'blue' : 'purple'" height="auto" fixed flat>
        <v-app-bar-nav-icon class="header__menu-burger">
          <router-link :to="prevUrl">
            <Icon name="arrow-back" />
          </router-link>
        </v-app-bar-nav-icon>
        <v-text-field id="search" ref="search" class="search-field" label="Recherche" placeholder="Recherche"
          :color="theme == 'adulte' ? 'blue-dark' : 'purple-dark'" regular single-line clearable
          @keyup.native="typeText" @click:clear="clearText"></v-text-field>
      </v-app-bar>

      <v-btn elevation="2" fab class="search-switch">
        <router-link :to="`/${$route.params.guide}`">
          <Icon name="folder" />
        </router-link>
      </v-btn>
    </div>

    <div class="featured" v-if="results.length == 0">
      <h2 class="text-h5">Les articles à la une</h2>
      <v-list class="featured__list">
        <v-list-item class="featured__list-item" tag="li" v-for="(article) in featured" :key="article.slug">
          <v-list-item-icon>
            <Icon name="file" />
          </v-list-item-icon>

          <v-list-item-content>
            <router-link :to="article.slug" class="featured__list-item-link">
              {{ article.title }}
            </router-link>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>

    <div class="results" v-if="results.length">
      <v-list class="results__list">
        <v-list-item class="results__list-item" tag="li" v-for="(article) in results" :key="article.slug">
          <v-list-item-icon>
            <Icon name="file" />
          </v-list-item-icon>

          <v-list-item-content>
            <router-link :to="article.slug" class="results__list-item-link">
              {{ article.title }}<br>
              <span v-html="article.excerpt"></span>
            </router-link>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>

  </section>
</template>

<script>
import { mapState } from 'vuex'
import pickBy from 'lodash.pickby'
import sortBy from 'lodash.sortby'
import forEach from 'lodash.foreach'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { removeAccents } from '@/utils/formatter'

export default {
  name: 'Search',

  props: {
    theme: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      results: [],
      prevUrl: ''
    }
  },

  computed: {
    featured () {
      return sortBy(
        pickBy(
          this.articles[this.$route.params.guide],
          function (article, key) {
            article.slug = key
            return article.featured !== false
          }
        ),
        'featured'
      )
    },
    ...mapState({
      articles: state => state.data.data.articles
    })
  },

  methods: {
    clearText: function () {
      this.results = []
    },

    typeText: function () {
      let text = document.getElementById('search').value.trim()
      if (text.length < 3) {
        this.results = []
        return
      }
      text = removeAccents(text)

      const strictSearch = new RegExp(`^.*?(?<pre>\\S*.{0,10})(?<match>\\b${text}\\b)(?<post>.{0,10}\\S*).*?$`, 'i')
      const permissiveSearch = new RegExp(`^.*?(?<pre>\\S*.{0,10})(?<match>${text})(?<post>.{0,10}\\S*).*?$`, 'i')

      let found = []
      let sorted = []

      /**
       * First, search the whole text (with spaces).
       * Search results will be sorted by priority (high to low):
       * - Exact titles
       * - Exact content
       * - Similar titles
       * - Similar content
       */
      forEach([strictSearch, permissiveSearch], (regex) => {
        forEach(this.articles[this.$route.params.guide], (article, slug) => {
          const _article = { ...article }
          const matches = _article['search'].match(regex)
          if (matches && !found.includes(slug)) {
            found.push(slug)
            sorted.push({
              slug: slug,
              title: article.title,
              excerpt: `…${matches.groups.pre}<strong>${matches.groups.match}</strong>${matches.groups.post}…`
            })
          }
        })
      })

      /**
       * If the search text contains space(s),
       * run a strict search on each term.
       */
      if (text.includes(' ')) {
        const terms = text.split(' ')
        forEach(this.articles[this.$route.params.guide], (article, slug) => {
          const _article = { ...article }
          let foundTerms = 0
          let result = {
            slug: slug,
            title: article.title,
            excerpt: ''
          }

          forEach(terms, (term) => {
            const regex = new RegExp(`^.*?(?<pre>.{0,5})(?<match>\\b${term}\\b)(?<post>.{0,5}).*?$`, 'i')
            let skipNextFields = false

            forEach(['title', 'search'], (field) => {
              if (skipNextFields) {
                return
              }

              const matches = _article[field].match(regex)

              if (matches && !found.includes(slug)) {
                foundTerms += 1
                skipNextFields = true
                result.excerpt += ` … ${matches.groups.pre}<strong>${matches.groups.match}</strong>${matches.groups.post}`
              }
            })
          })

          if (foundTerms === terms.length) {
            result.excerpt = result.excerpt.replace(/^[ …]+/, '')
            found.push(slug)
            sorted.push(result)
          }
        })
      }

      this.results = sorted
    }
  },

  mounted () {
    if (this.prevUrl.length == 0) {
      this.prevUrl = `/${this.$route.params.guide}`
    }

    // Add padding to main element due header fixed
    this.$refs.header.style.height = `${this.$refs.headerWrapper.$el.offsetHeight}px`

    // Focus search field
    this.$refs.search.$refs.input.focus()

    // Disable Scroll only on tablet & mobile
    if (window.innerWidth < 768) {
      disableBodyScroll(document.body)

      this.$refs.search.$refs.input.addEventListener('focus', () => {
        console.log('focus')
        disableBodyScroll(document.body)
      })

      this.$refs.search.$refs.input.addEventListener('blur', () => {
        console.log('blur')
        enableBodyScroll(document.body)
      })
    }
  },

  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (from && from.fullPath !== '/') {
        vm.prevUrl = from.fullPath
      } else {
        vm.prevUrl = `/${vm.$route.params.guide}`
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.v-btn--fab,
.header__menu-burger {
  a {
    display: flex;
  }

  i.icon {
    height: 24px;
    width: 24px;
  }
}

.header {
  &__wrapper {
    border-bottom: 1px solid rgba($dark, 0.12) !important;

    .v-toolbar__content {
      padding: 10px 22px !important;
    }

  }

  &__menu-burger {
    i.icon {
      color: $dark !important;
    }
  }
}

.search-switch {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: $dark !important;
  z-index: 99;

  .v-btn__content {
    height: 100%;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1 0 100%;
      width: 100%;
      height: 100%;
    }
  }

  i.icon {
    color: $white !important;
  }
}

.search-field {
  ::v-deep .v-input__slot {
    margin-bottom: 0px;

    &::before,
    &::after {
      display: none !important;
    }
  }

  ::v-deep .v-label,
  ::v-deep input {
    height: 46px;
    max-height: 46px;
    line-height: 46px;
    padding-left: 20px;
    font-size: 1.25rem;
    color: $dark;
    font-weight: 500;
    top: 0px;
  }

  ::v-deep input {
    caret-color: $white;

    &::placeholder {
      font-style: italic !important;
      color: $white !important;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      font-style: italic !important;
      color: $white !important;
    }

    &::-ms-input-placeholder {
      font-style: italic !important;
      color: $white !important;
    }
  }

  ::v-deep .v-text-field__details {
    display: none;
  }

  ::v-deep .v-input__append-inner {
    margin-top: 12px;
  }

  ::v-deep .v-icon {
    color: $white !important;
  }
}

.featured,
.results {
  h2 {
    padding: 20px 20px 0 20px;
  }

  hr {
    margin: 0 20px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    padding: 30px 20px 50px;

    &-item {
      display: inline-flex;
      align-items: center;
      padding: 0 !important;
      margin-bottom: 26px !important;
      min-height: auto !important;

      &:last-child {
        margin-bottom: 0 !important;
      }

      .v-list-item__icon {
        display: block;
        font-size: 24px;
        line-height: 1em;
        width: 24px;
        height: 24px;
        margin-top: 0;
        margin-bottom: 0;
        margin-right: 12px !important;
      }

      .v-list-item__content {
        display: inline-flex;
        padding: 0;

        span {
          font-size: 14px;
          color: transparentize($dark, 0.5);

          ::v-deep strong {
            font-weight: 900;
            color: $dark;
          }
        }
      }

      &-link {
        display: inline-block;
        font-size: 20px;
        font-weight: 500;
        line-height: 1.2;
        letter-spacing: 0.15px;
        color: rgba($dark, 0.87);
      }
    }
  }
}

section[theme="pediatrie"] {
  .header {
    &__wrapper {
      border-bottom: 1px solid rgba($purple-dark, 0.12) !important;

      .search-field {
        ::v-deep .v-label,
        ::v-deep input {
          color: $white;
        }
      }
    }

    &__menu {
      &-burger,
      &-back {
        background-color: $purple-dark;

        i.icon {
          color: $white !important;
        }
      }
    }
  }

  .search-switch {
    background-color: $purple-dark !important;
  }

  .featured,
  .results {
    &__list {
      &-item {
        .v-list-item__icon {
          color: rgba($purple-dark, 0.8);
        }

        .v-list-item__content {
          span {
            color: transparentize($purple-dark, 0.5);

            ::v-deep strong {
              color: $purple-dark;
            }
          }
        }

        &-link {
          color: rgba($purple-dark, 0.8);
        }
      }
    }

    hr {
      color: rgba($purple-dark, 0.8);
    }

    .text-h5 {
      color: rgba($purple-dark, 0.8);
    }
  }
}

section[theme="adulte"] {
  .header {
    &__wrapper {
      border-bottom: 1px solid rgba($blue-dark, 0.12) !important;

      .search-field {
        ::v-deep .v-label,
        ::v-deep input {
          color: $white;
        }
      }
    }

    &__menu {
      &-burger,
      &-back {
        background-color: $blue-light;

        i.icon {
          color: $white !important;
        }
      }
    }
  }

  .search-switch {
    background-color: $blue-light !important;
  }

  .featured,
  .results {
    &__list {
      &-item {
        .v-list-item__icon {
          color: rgba($blue-dark, 0.8);
        }

        .v-list-item__content {
          span {
            color: transparentize($blue-dark, 0.5);

            ::v-deep strong {
              color: $blue-dark;
            }
          }
        }

        &-link {
          color: rgba($blue-dark, 0.87);
        }
      }
    }

    hr {
      color: rgba($blue-dark, 0.8);
    }

    .text-h5 {
      color: rgba($blue-dark, 0.8);
    }
  }
}
</style>
