<template>
  <div class="header" ref="header" :theme="theme">
    <v-app-bar class="header__wrapper" ref="headerWrapper" :color="theme == 'adulte' ? 'blue' : 'purple'" height="auto" fixed flat>
      <v-app-bar-nav-icon class="header__menu-back" @click.stop="$router.push(parent)" v-if="folderView">
        <Icon name="arrow-back" />
      </v-app-bar-nav-icon>

      <v-app-bar-nav-icon class="header__menu-burger" @click.stop="openNav = !openNav" v-else>
        <Icon name="menu" />
      </v-app-bar-nav-icon>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
    </v-app-bar>

    <v-navigation-drawer class="header__menu" width="80%" hide-overlay fixed left temporary v-model="openNav">
      <div class="header__menu-top">
        <img :src="require('@/assets/icons/logo.svg')" class="header__menu-logo" alt="Médecins du monde" />
        <h2 class="header__menu-title">Guide Pratique en Médecine Palliative</h2>
      </div>

      <NavBar />

      <span class="data-version">Version des données : {{ version }}</span>
      <span class="data-published">Publiées le : {{ published }}</span>

      <a href="https://148.fr" class="created-by" target="_blank">
        Site réalisé par
        <Icon name="148-logo" />
      </a>
    </v-navigation-drawer>

    <v-btn elevation="2" fab class="search-switch">
      <router-link :to="`/${$route.params.guide}/recherche`">
        <Icon name="search" />
      </router-link>
    </v-btn>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Header',

  props: {
    title: {
      type: String,
      required: true
    },
    folderView: {
      type: Boolean,
      default: false,
      required: false
    },
    parent: {
      type: Object,
      required: false
    },
    theme: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      openNav: false
    }
  },

  computed: mapState({
    version: state => state.data.version,
    published: state => state.data.published
  }),

  mounted () {
    // Add padding to main element due header fixed
    this.$refs.header.style.height = `${this.$refs.headerWrapper.$el.offsetHeight}px`
  }
}
</script>

<style lang="scss">
.header {
  &[theme="pediatrie"] {
    .header {
      &__wrapper {
        border-bottom: 1px solid rgba($purple-dark, 0.12) !important;

        .v-toolbar__title {
          color: $white;
        }
      }

      &__menu {
        &-burger,
        &-back {
          background-color: $purple-dark;
          color: $white !important;
        }

        &-title {
          color: rgba($purple-dark, 0.8);
        }

        .data-version,
        .data-published,
        .created-by,
        .project-by,
        .authors,
        .directed-by {
          color: rgba($purple-dark, 0.4);
        }

        .created-by,
        .project-by {
          &:hover {
            color: rgba($purple-dark, 1);
          }
        }
      }
    }

    .search-switch {
      background-color: $purple-dark !important;
    }
  }

  &[theme="adulte"] {
    .header {
      &__wrapper {
        border-bottom: 1px solid rgba($blue-dark, 0.12) !important;

        .v-toolbar__title {
          color: $white;
        }
      }

      &__menu {
        &-burger,
        &-back {
          background-color: $blue-light;
          color: $white !important;
        }

        &-title {
          color: rgba($blue-dark, 0.8);
        }

        .data-version,
        .data-published,
        .created-by,
        .project-by,
        .authors,
        .directed-by {
          color: rgba($blue-dark, 0.4);
        }

        .created-by,
        .project-by {
          &:hover {
            color: rgba($blue-dark, 1);
          }
        }
      }
    }

    .search-switch {
      background-color: $blue-light !important;
    }
  }

  &__wrapper {
    border-bottom: 1px solid rgba($dark, 0.12) !important;

    .v-toolbar__content {
      padding: 10px 22px !important;

      .v-toolbar__title {
        font-weight: 500;
      }
    }

  }

  &__menu {
    padding: 30px 18px 0 20px !important;

    .data-version,
    .data-published {
      display: block;
      font-size: 10px;
      font-weight: 500;
      line-height: 2.4;
      letter-spacing: 0.07px;
      color: rgba($dark, 0.4);
      margin-top: 30px;
    }

    .data-published {
      margin-top: 0;
    }

    .created-by,
    .project-by {
      display: inline-flex;
      align-items: center;
      font-weight: 500;
      letter-spacing: 0.07px;
      color: rgba($dark, 0.4);
      margin-top: 20px;
      transition: color 0.3s ease-in-out;

      &:hover {
        color: rgba($dark, 1);
      }

      .icon {
        margin-left: 6px;
        width: 1.3em;
        height: 1.3em;
      }
    }

    .authors,
    .directed-by {
      display: block;
      font-weight: 500;
      letter-spacing: 0.07px;
      color: rgba($dark, 0.4);
      margin-top: 14px;
    }

    .created-by {
      font-size: 12px;
      margin-bottom: 20px;
    }

    .project-by,
    .authors,
    .directed-by {
      font-size: 14px;
    }

    &-burger,
    &-back {
      font-size: 1.5rem !important;
      background-color: $grey-medium;
      color: $dark !important;
      margin-left: 0 !important;

      .v-btn__content {
        height: 100%;

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1 0 100%;
          width: 100%;
          height: 100%;
        }
      }
    }

    &-top {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-title {
      @include title(1, rgba($dark, 0.87));

      line-height: 1.18;
      margin-bottom: 50px;
    }

    &-logo {
      width: 80px !important;
      height: auto !important;
      margin-bottom: 20px;
    }
  }
}
</style>
