<template>
  <div class="notices">
    <Message v-for="notice in notices"
      :key="notice.id"
      :icon="notice.icon"
      :type="notice.type"
      :message="notice.message" />
  </div>
</template>

<script>
export default {
  name: 'Notices',
  data: function () {
    return {
      notices: []
    }
  },
  created: function () {
    this.notices = this.$store.state.notices.all
    this.$store.commit('notices/clearNotices')
  }
}
</script>

<style lang="scss" scoped>
.notices {}
</style>
