const purple = Object.freeze({
  base: '#62466c',
  dark: '#332438'
})

const pink = Object.freeze({
  base: '#dd4c78'
})

const blue = Object.freeze({
  base: '#02427c',
  light: '#005ec5',
  dark: '#001e39'
})

const green = Object.freeze({
  base: '#5eb7b9'
})

const grey = Object.freeze({
  base: '#e1e2e4',
  light: '#f7f8f9',
  medium: '#d8d8d8'
})

const dark = Object.freeze({
  base: '#010101'
})

export default Object.freeze({
  purple,
  pink,
  blue,
  green,
  grey,
  dark
})
