<template>
  <section :theme="theme">
    <Header :title="current.title" :folder-view="folder.length > 0" :parent="parent" :theme="theme" />

    <v-list class="guide-menu__list" v-if="current.menu.length > 0">
      <v-list-item class="guide-menu__list-item" tag="li" v-for="(item, index) in current.menu" :key="item.url || index">
        <v-list-item-icon>
          <Icon name="folder" v-if="item.children.length > 0" />
          <Icon name="file" v-else />
        </v-list-item-icon>

        <v-list-item-content>
          <router-link :to="item.url" class="guide-menu__list-item-link">
            {{ item.title }}
          </router-link>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import deepFind from '../utils/deepFind'

export default {
  name: 'Guide',

  props: {
    folder: {
      type: String
    },
    theme: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      guide: {
        adulte: {
          title: 'Guide Adulte'
        },
        pediatrie: {
          title: 'Guide Pédiatrie'
        }
      }
    }
  },

  computed: {
    current () {
      // Check if is a folder
      if (this.folder && this.folder.length > 0) {
        const { title, children: menu } = deepFind(this.menu, 'url', this.folder)

        return { title, menu }
      } else if (this.$route.hash.length > 0) {
        const { title, children: menu } = deepFind(this.menu, 'url', this.$route.hash)

        return { title, menu }
      } else {
        return {
          title: this.guide[this.$route.params.guide].title,
          menu: this.menu
        }
      }
    },
    parent () {
      const meta = { path: this.$route.path }

      if (this.folder && this.folder.length > 0) {
        const hierarchy = this.$route.hash.split('/')

        if (hierarchy.length > 1) {
          hierarchy.pop()
          meta.hash = hierarchy.join('/')
        }
      }

      return meta
    },
    ...mapState({
      menus: state => state.data.data ? state.data.data.menus : [],
      menu () { return this.menus[this.$route.params.guide] || [] }
    })
  }
}
</script>

<style lang="scss" scoped>
.guide-menu {
  &__list {
    display: flex;
    flex-direction: column;
    padding: 50px 20px;

    &-item {
      display: inline-flex;
      align-items: center;
      padding: 0 !important;
      margin-bottom: 26px !important;
      min-height: auto !important;

      &:last-child {
        margin-bottom: 0 !important;
      }

      .v-list-item__icon {
        display: inline-flex;
        align-self: baseline;
        font-size: 1.5rem;
        line-height: 1em;
        width: 1.5rem;
        height: 1.5rem;
        margin-top: 0;
        margin-bottom: 0;
        margin-right: 12px !important;
      }

      .v-list-item__content {
        display: inline-flex;
        padding: 0;
      }

      &-link {
        display: inline-block;
      }
    }
  }
}

section[theme="pediatrie"] {
  .guide-menu {
    &__list {
      .guide-menu__list-item {
        color: rgba($purple-dark, 0.8) !important;
        transition: color 0.3s ease-in-out;

        &:hover {
          color: rgba($purple-dark, 1) !important;
        }

        &-link {
          @include paragraph('link', currentColor);
        }
      }
    }
  }
}

section[theme="adulte"] {
  .guide-menu {
    &__list {
      .guide-menu__list-item {
        color: rgba($blue-dark, 0.8) !important;
        transition: color 0.3s ease-in-out;

        &:hover {
          color: rgba($blue-dark, 1) !important;
        }

        &-link {
          @include paragraph('link', currentColor);
        }
      }
    }
  }
}
</style>
