<template>
  <v-app>
      <v-main>
        <router-view/>

        <v-alert
          :value="outdated && showNotice"
          transition="slide-x-reverse-transition"
          color="light-blue lighten-3"
          class="update-notice"
        >
          <p>Nouvelle version des données disponible.</p>
          <div class="update-notice__actions">
            <v-btn
              depressed
              @click="updateData"
              class="update-notice__btn"
              color="light-blue lighten-4"
            >Mettre à jour</v-btn>
          </div>
        </v-alert>

        <v-dialog
          v-model="openDisclaimer"
          transition="slide-y-transition"
          persistent
          content-class="disclaimer__dialog"
        >
          <v-card>
            <v-card-text class="disclaimer__text">
              Le GPMP est destiné aux professionnels de la santé. En cas d’éventuel dommage lié à l’utilisation de ce guide, MdM ne peut en aucun cas être tenu pour responsable.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                depressed
                @click="acceptDisclaimer()"
                class="disclaimer__btn"
                color="blue-light"
              >J'ai compris</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-main>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import uniq from 'lodash.uniq'
import { setCookie, getCookie } from './utils/cookies'

export default {
  name: 'App',

  data () {
    return {
      openDisclaimer: true,
      showNotice: false
    }
  },

  computed: mapState({
    data: state => state.data.data,
    outdated: state => state.data.outdated
  }),

  created () {
    this.$store.dispatch('data/check')
    this.cacheApiAssets()
  },

  beforeMount () {
    if (getCookie('disclaimer--accepted') === 'true') {
      this.openDisclaimer = false
      this.showNotice = true
    }
  },

  methods: {
    updateData () {
      this.$store.dispatch('data/fetchData')
    },

    async cacheApiAssets () {
      const apiRootUrl = new URL(process.env.VUE_APP_API_ROOT)
      const assetsRoot = `${apiRootUrl.protocol}\\/\\/${apiRootUrl.hostname}`
      const regex = new RegExp(`${assetsRoot}\\/app\\/uploads\\/\\d+\\/\\d+\\/[^\\\\'"]+`, 'ig')
      const content = JSON.stringify(this.data.articles)
      const assetsUrls = uniq(content.match(regex))

      for await (const assetUrl of assetsUrls) {
        let img = new Image()
        img.src = assetUrl
      }
    },

    acceptDisclaimer () {
      const date = new Date(Date.now() + 3600 * 1000 * 24 * 365) // 1 year

      setCookie('disclaimer--accepted', 'true', date.toUTCString(), '/')
      this.openDisclaimer = false
      this.showNotice = true
    }
  }
}
</script>

<style lang="scss">
.update-notice {
  position: fixed !important;
  bottom: 1em;
  right: 1em;
  max-width: calc(100% - 2em);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $grey-light;
  margin: 0 !important;
  z-index: 210;

  @media screen and (min-width: 768px) {
    top: 90px;
    bottom: initial;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__btn {
    font-size: 0.75rem !important;
  }

  p {
    @include paragraph("small", $dark);

    margin-top: 0;
    margin-bottom: 0.8em;
  }
}

.disclaimer {
  &__text {
    font-size: 15px !important;
    padding: 20px 24px !important;
  }

  &__btn {
    font-size: 0.75rem !important;
    color: $white !important;
  }
}
</style>
