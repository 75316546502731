<template>
  <div class="questionnaire">
    <v-btn
      depressed
      @click="openPopup = true"
      class="questionnaire__btn"
      :color="theme == 'adulte' ? 'blue-light' : 'pink'"
    >
      <Icon name="form" />
      <span class="questionnaire__btn-text" v-html="form.title"></span>
    </v-btn>

    <v-dialog
      v-model="openPopup"
      fullscreen
      hide-overlay
      transition="scroll-x-transition"
      :content-class="'v-dialog-portrait v-dialog-theme--' + theme"
    >
      <div @click="hidePopup()" class="questionnaire__popup-close">
        <Icon name="close" />
      </div>

      <v-sheet min-height="100%" height="auto">
        <v-carousel
          v-model="screenIndex"
          hide-delimiters
          :show-arrows="false"
          :touchless="true"
          height="auto"
          class="questionnaire__popup"
        >
          <v-carousel-item class="questionnaire__popup-slide questions">
            <h2 class="questionnaire__popup-title" v-html="form.title"></h2>

            <p class="questionnaire__popup-desc" v-html="form.desc"></p>

            <v-sheet
              rounded
              v-for="(section, sectionKey) in form.sections"
              :key="sectionKey"
              class="questionnaire__section"
            >
              <h3 class="questionnaire__section-title" v-if="section.title && section.title.length > 0">{{ sectionKey + 1 }} - {{ section.title }}</h3>

              <div class="questionnaire__question" v-for="(question, questionKey) in section.questions" :key="questionKey">
                <h4 class="questionnaire__question-title" v-html="question.title"></h4>
                <p class="questionnaire__question-desc" v-html="question.desc" v-if="question.desc"></p>

                <v-radio-group class="questionnaire__answers" v-model.number="results[sectionKey][questionKey]" row v-if="question.type == 'boolean'">
                  <v-radio label="Oui" value="1" on-icon="mdi-check-circle"></v-radio>
                  <v-radio label="Non" value="0" on-icon="mdi-check-circle"></v-radio>
                </v-radio-group>

                <v-radio-group class="questionnaire__answers" v-model.number="results[sectionKey][questionKey]" column v-if="question.type == 'numerical'">
                  <v-radio
                    v-for="(answer, answerKey) in question.answers"
                    :value="answerKey"
                    :key="answerKey"
                    on-icon="mdi-check-circle"
                  >
                    <template v-slot:label>
                      <span><strong>{{ parseInt(answerKey) }}</strong> - {{answer}}</span>
                    </template>
                  </v-radio>
                </v-radio-group>
              </div>

            </v-sheet>

            <div class="questionnaire__popup-action">
              <v-btn
                depressed
                @click="displayScore()"
                class="questionnaire__popup-btn"
                :color="theme == 'adulte' ? 'blue-light' : 'pink'"
              >
                <span class="questionnaire__popup-btn-text">Afficher le résultat</span>
              </v-btn>
            </div>
          </v-carousel-item>

          <v-carousel-item class="questionnaire__popup-slide result">
            <h2 class="questionnaire__popup-title">Interprétation du résultat</h2>

            <p class="questionnaire__result-score">Score obtenu : {{ score }}/{{ total }}</p>
            <p class="questionnaire__result-interpretation" v-html="interpretation"></p>

            <div class="questionnaire__popup-reset">
              <v-btn
                depressed
                @click="resetForm()"
                class="questionnaire__popup-btn"
                :color="theme == 'adulte' ? 'blue-light' : 'pink'"
              >
                <span class="questionnaire__popup-btn-text">Recommencer le questionnaire</span>
              </v-btn>

              <span
                @click="hidePopup()"
                class="echelle-pediatrie__popup-back"
              >
                <Icon name="arrow-back" />
                <span class="echelle-pediatrie__popup-btn-text">Retour à l’article</span>
              </span>
            </div>
          </v-carousel-item>
        </v-carousel>
      </v-sheet>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Questionnaire',

  props: {
    id: {
      type: [Number, String],
      required: true
    },
    theme: {
      type: String,
      // required: true
      default: 'pediatrie'
    }
  },

  data () {
    return {
      form: null,
      openPopup: false,
      screenIndex: 0,
      results: [],
      score: 0,
      total: 0,
      interpretation: ''
    }
  },

  computed: {
    ...mapState({
      forms: state => state.data.data.forms
    }),
  },

  created () {
    this.form = this.forms[this.id] ?? null

    this.initVars()
  },

  methods: {
    hidePopup () {
      this.openPopup = false

      setTimeout(() => {
        this.resetForm()
      }, 300)
    },

    initVars() {
      for (let i = 0; i < this.form.sections.length; i++) {
        const questions = this.form.sections[i].questions;

        this.results[i] = []

        for(let j = 0; j < questions.length; j++) {
          const question = this.form.sections[i].questions[j]

          this.results[i][j] = null

          if (question.type == 'boolean') {
            this.total += 1 // 1 is the highest score for a boolean question
          } else {
            const highScore = parseInt(Object.keys(question.answers).pop()) // Get the last answer key, should be the highest score
            this.total += highScore
          }
        }
      }
    },

    resetForm () {
      this.screenIndex = 0
      this.score = 0

      for (let i = 0; i < this.results.length; i++) {
        const sections = this.results[i]

        for(let j = 0; j < sections.length; j++) {
          this.results[i][j] = null
        }
      }
    },

    displayScore() {
      for (let i = 0; i < this.results.length; i++) {
        this.score += this.results[i].reduce((acc, current) => acc + current)
      }

      for (let i = 0; i < this.form.results.length; i++) {
        const item = this.form.results[i];

        if (this.score >= item.min && this.score <= item.max) {
          this.interpretation = item.text
        }
      }

      this.screenIndex++
    }
  }
}
</script>

<style lang="scss">
.questionnaire {
  &__btn {
    color: $white !important;
    min-height: 36px !important;
    height: auto !important;
    max-width: 100% !important;
    white-space: pre-wrap !important;
    padding: 8px 16px !important;

    @media screen and (min-width: 767px) and (orientation: portrait) {
      max-width: 50% !important;
    }

    @media screen and (min-width: 600px) and (orientation: landscape) {
      max-width: 50% !important;
    }

    .v-btn__content {
      max-width: 100%;
    }

    &-text {
      @include paragraph("small", $white);

      font-weight: 700;
      text-transform: uppercase;
      line-height: 1.143;
      text-align: left;
    }

    i.icon {
      flex-shrink: 0;
      width: 20px;
      height: 20px;
      color: $white;
      margin-right: 10px;
    }
  }

  &__popup {
    &-close {
      position: fixed;
      top: 20px;
      right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      width: 50px !important;
      height: 50px !important;
      border-radius: 50px;
      z-index: 1;

      i.icon {
        color: $white;
      }
    }

    &-slide {
      position: relative;
      padding: 90px 20px 20px 20px;
      overflow: auto;

      .v-carousel__item {
        overflow: visible;
      }

      .v-slider--horizontal {
        margin: 0;
      }

      .v-input__slot {
        margin-bottom: 0;
      }

      .v-slider__thumb {
        left: -1px;
        width: 2px;
        height: 30px;
        border-radius: 0;

        &::before {
          content: none;
        }
      }

      .v-slider__thumb-label {
        color: $blue;
      }

      .v-slider__track-container,
      .v-slider__ticks-container,
      .v-slider__tick {
        height: 30px !important;
      }

      .v-slider__tick {
        top: calc(50% - 15px) !important;
        background-color: $white !important;

        &:nth-child(even) {
          display: none !important;
        }

        .v-slider__tick-label {
          @include paragraph("information", rgba($blue-dark, 0.8));

          top: 100% !important;
          margin-top: 10px;
        }

        &:first-child,
        &:last-child {
          .v-slider__tick-label {
            transform: translateX(-50%) !important;
          }
        }
      }

      .v-messages {
        display: none;
      }
    }

    &-title {
      margin-bottom: 22px;
    }

    &-desc {
      margin-bottom: 20px !important;
    }

    &-back {
      @include paragraph("information", rgba($purple-dark, 0.5));

      display: inline-flex;
      align-items: center;
      margin-top: 30px;

      i.icon {
        width: 24px;
        height: 24px;
        color: $dark;
      }
    }

    &-action {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &-reset {
      display: inline-flex;
      flex-direction: column;
    }

    &-btn {
      font-weight: 700;
    }
  }

  &__section {
    background: $grey-light !important;
    padding: 20px;
    margin-bottom: 20px;

    &-title {
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: 20px;
    }
  }

  &__question {
    margin-top: 20px;

    &:first-child {
      margin-top: 0;
    }

    &-desc {
      img {
        max-width: 100%;
        height: auto;
      }
    }

    .v-radio {
      .v-label {
        strong {
          font-weight: 700;
        }
      }
    }

    .v-input--is-label-active {
      .v-radio {
        opacity: 0.3;
        transition: opacity 0.2s ease-in-out;

        &.v-item--active {
          opacity: 1;
        }
      }
    }
  }

  &__answers {
    margin-top: 0 !important;
    padding-top: 10px !important;
  }

  &__result-score {
    @include title(2, rgba($dark, 0.87));
  }

  &__result-interpretation {
    margin-bottom: 20px !important;
  }
}

.v-dialog-theme--pediatrie {
  color: $purple-dark !important;

  .questionnaire {
    &__popup {
      &-close {
        background-color: $pink !important;
      }

      &-title {
        @include title(1, $purple-dark);
      }

      &-desc {
        @include paragraph("normal", rgba($purple-dark, 0.8));
      }
    }

    &__section {
      &-title {
        @include title(3, $purple-dark);
      }
    }

    &__question {
      &-title {
        @include title(4, $purple-dark);
      }

      &-desc {
        @include paragraph("normal", rgba($purple-dark, 0.8));
      }
    }

    &__answers {
      .v-radio {
        .v-icon {
          color: $pink !important;
        }
      }

      .v-label {
        color: rgba($purple-dark, 0.8) !important;
      }
    }

    &__result-interpretation {
      @include paragraph("normal", rgba($purple-dark, 0.8));
    }
  }
}

.v-dialog-theme--adulte {
  color: $blue-dark !important;

  .questionnaire {
    &__popup {
      &-close {
        background-color: $blue-light !important;
      }

      &-title {
        @include title(1, $blue-dark);
      }

      &-desc {
        @include paragraph("normal", rgba($blue-dark, 0.8));
      }
    }

    &__section {
      &-title {
        @include title(3, $blue-dark);
      }
    }

    &__question {
      &-title {
        @include title(4, $blue-dark);
      }

      &-desc {
        @include paragraph("normal", rgba($blue-dark, 0.8));
      }
    }

    &__answers {
      .v-radio {
        .v-icon {
          color: $blue !important;
        }
      }

      .v-label {
        color: rgba($blue-dark, 0.8) !important;
      }
    }

    &__result-interpretation {
      @include paragraph("normal", rgba($purple-dark, 0.8));
    }
  }
}
</style>
