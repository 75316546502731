<template>
  <section class="page" :theme="theme">
    <div class="page__header">
      <span @click="goBack()" class="page__back-btn">
        <Icon name="close" />
      </span>
    </div>


    <div class="page__body" v-if="page">
      <h1>{{ page.title }}</h1>

      <ShortcodeParser tag="div" class="page__body-content" :content="page.content" :theme="theme" />
    </div>

    <div v-else>
      <Message message="Page introuvable, si vous n'avez pas de réseau, essayez de repasser par la page d'accueil" type="error" class="centered" />
    </div>

    <ImagePopup />
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Page',

  props: {
    theme: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      prevUrl: null,
      useHistory: false,
    }
  },

  computed: mapState({
    pages: state => state.data.data.pages,
    page(state) {
      return state.data.data.pages[this.$route.params.slug]
    }
  }),

  methods: {
    goBack() {
      if (this.useHistory) {
        this.$router.go(-1)
      } else {
        this.$router.push(this.prevUrl)
      }
    }
  },

  beforeRouteEnter (to, from, next) {
    // Set prev url
    next(vm => {
      if (Object.keys(from.params).length > 0 && from.params.guide.length > 0) {
        // If user refresh the page keep the info
        if (from.path.includes(`${from.params.guide}/recherche`)) {
          window.sessionStorage.setItem('prevUrl', JSON.stringify({path: `/${from.params.guide}/recherche`, hash: from.hash}))
          vm.prevUrl = {path: `/${from.params.guide}/recherche`, hash: from.hash}
        } else {
          window.sessionStorage.setItem('prevUrl', JSON.stringify({path: `/${from.params.guide}`, hash: from.hash}))
          vm.prevUrl = {path: `/${from.params.guide}`, hash: from.hash}
        }
      } else if (window.sessionStorage.getItem('prevUrl')) {
        // If we have kept the info in session storage, set it in prev url
        vm.prevUrl = JSON.parse(window.sessionStorage.getItem('prevUrl'))
      } else {
        // If params is empty or we don't have guide info
        // set default prev url
        vm.prevUrl = {path: '/adulte'}
      }
    })
  },

  beforeRouteUpdate (to, from, next) {
    if (Object.keys(from.params).length > 0 && from.params.guide.length > 0) {
      if (from.params.slug && from.params.slug.length > 0) {
        window.sessionStorage.setItem('prevUrl', '')
        this.prevUrl = ''
        this.useHistory = true
      } else {
        window.sessionStorage.setItem('prevUrl', JSON.stringify({path: `/${from.params.guide}`, hash: from.hash}))
        this.prevUrl = {path: `/${from.params.guide}`, hash: from.hash}
        this.useHistory = false
      }
    } else if (window.sessionStorage.getItem('prevUrl')) {
      // If we have kept the info in session storage, set it in prev url
      this.prevUrl = JSON.parse(window.sessionStorage.getItem('prevUrl'))
      this.useHistory = false
    } else {
      // If params is empty or we don't have guide info
      // set default prev url
      this.prevUrl = {path: '/adulte'}
      this.useHistory = false
    }

    this.page = this.pages[to.params.slug] ?? null
    next()
  },
}
</script>

<style lang="scss">
.page {
  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px;
  }

  &__back-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    cursor: pointer;
    background-color: $dark;

    i.icon {
      color: $white;
    }
  }

  &__body {
    position: relative;
    display: block;
    padding: 0 20px;

    h1,
    h2,
    h3,
    h4,
    h5 {
      margin-bottom: 20px;
    }

    h1 {
      @include title(1);
    }

    h2 {
      @include title(2);
    }

    h3 {
      @include title(3);
    }

    h4 {
      @include title(4);
    }

    h5 {
      @include title(5);
    }

    ul {
      padding-left: 10px;
      margin-bottom: 40px;

      li {
        @include paragraph("normal");

        position: relative;
        padding-left: 18px;

        &::before {
          content: "•";
          position: absolute;
          top: 0;
          left: 0;
          font-weight: 700;
        }

        p {
          display: inline-block;
          margin-bottom: 0;
        }

        strong {
          font-weight: 700;
        }

        ul {
          padding-left: 18px;
          margin-bottom: 0;

          li {
            &::before {
              content: "-";
            }
          }
        }
      }
    }

    p {
      @include paragraph("normal");

      margin-bottom: 30px;

      &:empty {
        display: none;
      }

      strong {
        font-weight: 700;
      }

      em {
        font-style: italic;
      }
    }

    .table-wrapper {
      @media screen and (max-width: 767px) {
        position: relative;
        padding-top: 26px;
        overflow: hidden;

        &::before {
          content: 'Scrollez horizontalement →';
          position: absolute;
          top: 0;
          right: 0;
          display: inline-block;
          font-size: 11px;
          color: rgba($dark, 0.8);
          margin-bottom: 10px;
        }

        .table-inner {
          overflow: auto;
        }
      }
    }

    table {
      width: 100%;
      border-collapse: collapse;
      border-radius: 4px;

      td {
        padding: 8px;
        border: 1px solid rgba($dark, 0.8);
      }
    }
  }
}
</style>
