import Vue from 'vue'
import Vuex from 'vuex'

import notices from '@/store/modules/notices'
import data from '@/store/modules/data'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    notices,
    data
  }
})

export default store
