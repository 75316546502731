/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production' && !(process.env.DISABLE_SW ?? false)) {
  register(`${process.env.BASE_URL}sw.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')

      // // New Update Found (Call the first time too)
      // const sw = self.registration.installing

      // // Service Worker state change
      // sw.addEventListener('statechange', () => {
      //   // Service Worker installed (waiting)
      //   if (sw.state === 'installed') {
      //     // New SW installed, so skip waiting
      //     self.skipWaiting()
      //   }
      // })
    },
    updated () {
      console.log('New content is available; please refresh.')

      // Remove registered cache (assets-cache & images-cache) due updated Service Worker
      // event.waitUntil(
      //   caches.keys().then(cacheNames => {
      //     if (cacheNames.length > 0) {
      //       cacheNames.forEach(cacheName => {
      //         if (cacheName.includes(`${self.location.hostname}-`) && cacheName !== `${self.location.hostname}-precache`) {
      //           caches.delete(cacheName);
      //         }
      //       })
      //     }
      //   })
      // )

    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
