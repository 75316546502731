import axios from 'axios'

const state = () => ({
  version: null,
  published: null,
  outdated: false,
  data: null
})

const getters = {}

const mutations = {
  setVersion (state, version) {
    state.version = parseInt(version)
    window.localStorage.setItem('data_version', state.version)
  },
  setPublished (state, published) {
    state.published = published
    window.localStorage.setItem('data_published', state.published)
  },
  setData (state, data) {
    state.data = data
    window.localStorage.setItem('data', JSON.stringify(state.data))
  },
  clearData (state) {
    state.data = []
  },
  flagOutdated (state, outdated) {
    state.outdated = outdated
  }
}

const actions = {
  check ({ commit, state }) {
    // Retrieve local data
    if (state.version === null) {
      const currentVersion = window.localStorage.getItem('data_version')
      if (currentVersion) {
        commit('setVersion', currentVersion)
      }
    }
    if (state.published === null) {
      const currentPublished = window.localStorage.getItem('data_published')
      if (currentPublished) {
        commit('setPublished', currentPublished)
      }
    }
    if (state.data === null) {
      const currentData = window.localStorage.getItem('data')
      if (currentData) {
        commit('setData', JSON.parse(currentData))
      }
    }

    // Check for updates
    return axios.get('data/v1/version')
      .then(response => {
        commit('flagOutdated', response.data.version !== state.version)
      })
      .catch(e => { console.log(e) })
  },

  fetchVersion ({ commit }) {
    return axios.get('data/v1/version')
      .then(response => {
        commit('setVersion', response.data.version)
        commit('setPublished', response.data.published)
      })
      .catch(e => { console.log(e) })
  },

  fetchData ({ dispatch, commit, state }) {
    return dispatch('fetchVersion').then(() => {
      return axios.get(`data/v1/data/${state.version}`)
        .then(response => {
          commit('setData', response.data)
          commit('flagOutdated', false)
        })
        .catch(e => { console.log(e) })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
