import Vue from 'vue'
import VueRouter from 'vue-router'

import Guide from '@/views/Guide.vue'
import Search from '@/views/Search.vue'
import Article from '@/views/Article.vue'
import Page from '@/views/Page.vue'

const routes = [
  { path: '/', redirect: '/adulte' },
  { path: '/page/:slug', component: Page, props: () => ({ theme: 'default' }) },
  { path: '/:guide', component: Guide, props: route => ({ folder: route.hash, theme: route.params.guide }) },
  { path: '/:guide/recherche', component: Search, props: route => ({ theme: route.params.guide }) },
  { path: '/:guide/:slug', component: Article, props: route => ({ theme: route.params.guide }) }
]

Vue.use(VueRouter)
const router = new VueRouter({
  scrollBehavior (to, from, savedPosition) { return { x: 0, y: 0 } },
  mode: 'history',
  routes
})

export default router
