<template>
  <div class="echelle-pediatrie">
    <v-btn
      depressed
      @click="openPopup = true"
      class="echelle-pediatrie__btn"
      color="pink"
    >
      <Icon name="dots" />
      <span class="echelle-pediatrie__btn-text">Échelle visuelle analogique</span>
    </v-btn>

    <v-dialog
      v-model="openPopup"
      fullscreen
      hide-overlay
      eager
      transition="scroll-x-transition"
    >
      <div @click="hidePopup()" class="echelle-pediatrie__popup-close">
        <Icon name="close" />
      </div>

      <v-sheet min-height="100%" height="auto">
        <v-carousel
          v-model="screenIndex"
          hide-delimiters
          :show-arrows="false"
          :touchless="true"
          height="auto"
          class="echelle-pediatrie__popup"
        >
          <v-carousel-item eager class="echelle-pediatrie__popup-slide intro">
            <h2 class="echelle-adultes__popup-title">Échelle Visuelle Analogique</h2>

            <p class="echelle-adultes__popup-content">
              Pour utiliser cette échelle placer la réglette devant le patient, il doit placer le curseur avec son doigt au niveau qui selon lui correspond à sa douleur.
              À noter que l’application ne conserve aucune donnée, il vous appartient de reporter le score dans le dossier patient.
            </p>

            <v-btn
              depressed
              @click="screenIndex++"
              class="echelle-pediatrie__popup-btn"
              color="pink"
            >
              <span class="echelle-pediatrie__popup-btn-text">afficher l’échelle analogique</span>
            </v-btn>
          </v-carousel-item>

          <v-carousel-item eager class="echelle-pediatrie__popup-slide evaluation">
            <div class="echelle-pediatrie__popup-pain">
              <v-slider
                v-model="painLvl"
                color="grey"
                track-color="grey"
                thumb-color="purple"
                :thumb-size="thumbSize"
                thumb-label="always"
                :min="painMin"
                :max="painMax"
                step="0.5"
                ref="slider"
                :style="`padding-top:${thumbSize + (thumbSize / 10)}px`"
              ></v-slider>

              <div class="echelle-pediatrie__popup-pain-icons">
                <Icon name="happy" />
                <Icon name="sad" />
              </div>
            </div>

            <div class="echelle-pediatrie__popup-actions">
              <v-btn
                depressed
                @click="screenIndex++"
                class="echelle-pediatrie__popup-btn"
                color="pink"
              >
                <span class="echelle-pediatrie__popup-btn-text">afficher le résultat</span>
              </v-btn>
            </div>

            <p class="echelle-adultes__popup-infos">
              Pour une utilisation optimale sur téléphone mobile ou tablette, veuillez tourner votre écran au format paysage
            </p>
          </v-carousel-item>

          <v-carousel-item eager class="echelle-pediatrie__popup-slide result">
            <h2 class="echelle-pediatrie__popup-title">Interprétation du résultat</h2>

            <v-slider
              v-model="painLvl"
              color="grey"
              track-color="grey"
              thumb-color="purple"
              :thumb-size="thumbSize"
              thumb-label="always"
              :tick-labels="ticksLabels"
              tick-size="1"
              :min="painMin"
              :max="painMax"
              step="0.5"
              class="echelle-pediatrie__popup-rate"
              :style="`padding-top:${thumbSize + (thumbSize / 10)}px`"
              readonly
            ></v-slider>

            <p class="echelle-pediatrie__popup-content">
              Résultat : {{painLvl}}/10 <br>
              {{result}} <br>
              Seuil de traitement : 3/10
            </p>

            <div class="echelle-pediatrie__popup-actions">
              <v-btn
                depressed
                @click="screenIndex = 1"
                class="echelle-pediatrie__popup-btn"
                color="pink"
              >
                <span class="echelle-pediatrie__popup-btn-text">afficher l’échelle analogique</span>
              </v-btn>

              <span
                @click="hidePopup()"
                class="echelle-pediatrie__popup-back"
              >
                <Icon name="arrow-back" />
                <span class="echelle-pediatrie__popup-btn-text">Retour à l’article</span>
              </span>
            </div>
          </v-carousel-item>
        </v-carousel>
      </v-sheet>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'EchellePediatrie',

  data () {
    return {
      openPopup: false,
      screenIndex: 0,
      painLvl: 0,
      painInterpretation: {
        '0': 'Douleur d’intensité légère',
        '0.5': 'Douleur d’intensité légère',
        '1': 'Douleur d’intensité légère',
        '1.5': 'Douleur d’intensité légère',
        '2': 'Douleur d’intensité légère',
        '2.5': 'Douleur d’intensité légère',
        '3': 'Douleur d’intensité légère',
        '3.5': 'Douleur d’intensité modérée',
        '4': 'Douleur d’intensité modérée',
        '4.5': 'Douleur d’intensité modérée',
        '5': 'Douleur d’intensité modérée',
        '5.5': 'Douleur intense',
        '6': 'Douleur intense',
        '6.5': 'Douleur intense',
        '7': 'Douleur intense',
        '7.5': 'Douleur très intense',
        '8': 'Douleur très intense',
        '8.5': 'Douleur très intense',
        '9': 'Douleur très intense',
        '9.5': 'Douleur très intense',
        '10': 'Douleur très intense'
      },
      thumbSize: 40,
      painMin: 0,
      painMax: 10,
      ticksLabels: ['0', '0.5', '1', '1.5', '2', '2.5', '3', '3.5', '4', '4.5', '5', '5.5', '6', '6.5', '7', '7.5', '8', '8.5', '9', '9.5', '10']
    }
  },

  computed: {
    result () {
      return this.painInterpretation[this.painLvl]
    }
  },

  mounted () {
    // Hotfix : Do not remove next line, it allows the component (v-slider) to take into account the touchmove
    this.$refs.slider.$el.querySelector('.v-slider__thumb-label').addEventListener('touchmove', () => {})
  },

  methods: {
    hidePopup () {
      this.openPopup = false

      setTimeout(() => {
        this.screenIndex = 0
      }, 300)
    }
  }
}
</script>

<style lang="scss">
.echelle-pediatrie {
  &__btn {
    color: $white !important;

    &-text {
      @include paragraph("small", $white);

      font-weight: 700;
      text-transform: uppercase;
      line-height: 1.143;
    }

    i.icon {
      width: 24px;
      height: 24px;
      color: $white;
      margin-right: 10px;
    }
  }

  &__popup {
    &-close {
      position: fixed;
      top: 10px;
      right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      width: 50px !important;
      height: 50px !important;
      border-radius: 50px;
      background-color: $purple;
      z-index: 1;

      i.icon {
        color: $white;
      }
    }

    &-slide {
      position: relative;
      padding: 60px 40px 20px 40px;
      overflow: auto;

      @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: portrait) {
        padding: 60px 30px 20px 30px;
      }

      .v-carousel__item {
        overflow: visible;
      }

      .v-slider--horizontal {
        margin: 0;
      }

      .v-input__slot {
        margin-bottom: 0;
      }

      .v-slider__thumb {
        left: -1px;
        width: 2px;
        height: 30px;
        border-radius: 0;

        &::before {
          content: none;
        }
      }

      .v-slider__thumb-label {
        color: $purple;
      }

      .v-slider__track-container,
      .v-slider__ticks-container,
      .v-slider__tick {
        height: 30px !important;
      }

      .v-slider__tick {
        top: calc(50% - 15px) !important;
        background-color: $white !important;

        &:nth-child(even) {
          display: none !important;
        }

        .v-slider__tick-label {
          @include paragraph("information", rgba($purple-dark, 0.8));

          top: 100% !important;
          margin-top: 10px;
        }

        &:first-child,
        &:last-child {
          .v-slider__tick-label {
            transform: translateX(-50%) !important;
          }
        }
      }

      .v-messages {
        display: none;
      }

      &.result {
        .echelle-pediatrie__popup {
          &-content {
            margin-bottom: 40px !important;
          }

          &-actions {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          }
        }
      }

      &.evaluation {
        .echelle-pediatrie__popup {
          &-actions {
            display: flex;
            align-items: center;
            justify-content: flex-end;
          }
        }
      }
    }

    &-title {
      @include title(1, $purple-dark);

      margin-bottom: 22px;
    }

    &-content {
      @include paragraph("normal", rgba($purple-dark, 0.8));

      margin-bottom: 20px !important;
    }

    &-infos {
      @include paragraph("information", rgba($blue-dark, 0.8));

      line-height: 1.3em;
      margin-top: 20px !important;
      margin-bottom: 0 !important;

      @media screen and (min-width: 320px) and (max-width: 1025px) and (orientation: landscape) {
        display: none;
      }
    }

    &-pain {
      margin-top: 36px;
      margin-bottom: 65px;

      &-icons {
        display: flex;
        align-items: center;
        justify-content: space-between;

        i.icon {
          width: 50px;
          height: 50px;
        }
      }
    }

    &-rate {
      padding-bottom: 34px;
      margin-bottom: 14px;

      .v-slider__thumb-label {
        @include paragraph("small", $white);

        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &-back {
      @include paragraph("information", rgba($purple-dark, 0.5));

      display: inline-flex;
      align-items: center;
      margin-top: 30px;

      i.icon {
        width: 24px;
        height: 24px;
        color: $dark;
      }
    }
  }
}
</style>
