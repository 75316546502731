<script>
import Vue from 'vue'
import EchellePediatrie from './EchellePediatrie'
import EchelleAdultes from './EchelleAdultes'
import Questionnaire from './Questionnaire'

export default {
  name: 'ShortcodeParser',

  props: {
    content: {
      type: String,
      required: true
    },
    tag: {
      type: String,
      required: false,
      default: 'div'
    },
    classes: {
      type: String,
      required: false,
      default: ''
    },
    theme: {
      type: String,
      required: false,
      default: 'adulte'
    }
  },

  render (createElement) {
    const regex = /\[(?<type>echelle-(?:adultes|pediatrie)|questionnaire)\s?(?<id>id=(?:\d*))?\]/g

    let template = this.content.replace(regex, `<$<type> $<id> theme="${this.theme}" />`)

    // Wrap table tags
    template = template.replaceAll('<table', '<div class="table-wrapper"><div class="table-inner"><table').replace('</table>', '</table></div></div>')

    var component = Vue.component('Shortcode', {
      template: `<${this.tag} ${this.classes.length > 0 ? `class="${this.classes}"` : ''}>${template}</${this.tag}>`,
      components: {
        'echelle-pediatrie': EchellePediatrie,
        'echelle-adultes': EchelleAdultes,
        'questionnaire': Questionnaire
      }
    })

    return createElement(component)
  }
}
</script>
