<template>
  <v-list class="header__nav" tag="nav" nav>
    <v-list-item-group class="header__nav-list main" tag="ul">
      <v-list-item class="header__nav-list-item" tag="li" v-for="(item, index) in nav.main" :key="index">
        <router-link :to="item.url" class="header__nav-list-item-link" :class="item.guide">
          <v-list-item-icon>
            <Icon :name="item.icon" />
          </v-list-item-icon>

          <v-list-item-content>
              {{ item.text }}
          </v-list-item-content>
        </router-link>
      </v-list-item>
    </v-list-item-group>

    <h3 class="header__nav-subheader">Partenaires</h3>
    <v-list-item-group class="header__nav-list" tag="ul">
      <v-list-item class="header__nav-list-item" tag="li" v-for="(item, index) in nav.partners" :key="index">
        <a :href="item.url" class="header__nav-list-item-link" :target="item.external ? '_blank' : undefined">
          {{ item.text }}
          <Icon name="external" v-if="item.external" />
        </a>
      </v-list-item>
    </v-list-item-group>

    <v-list-item-group class="header__nav-list" tag="ul">
      <v-list-item class="header__nav-list-item" tag="li" v-for="(item, index) in nav.others" :key="index">
        <a :href="item.url" class="header__nav-list-item-link" :target="item.external ? '_blank' : undefined">
          {{ item.text }}
          <Icon name="external" v-if="item.external" />
        </a>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import { mapState } from 'vuex'
import findKey from 'lodash.findkey'

export default {
  name: 'NavBar',

  computed: mapState({
    nav: state => {
      let nav = {
        main: [
          {
            url: '/adulte',
            icon: 'adult',
            text: 'Guide Adulte',
            guide: 'adulte'
          },
          {
            url: '/pediatrie',
            icon: 'baby',
            text: 'Guide Pédiatrie',
            guide: 'pediatrie'
          }
        ],
        partners: [
          {
            url: 'https://www.ffisp.org/',
            text: 'FISP',
            external: true
          },
          {
            url: 'https://www.pediatriepalliative.org/',
            text: 'RFSPP',
            external: true
          }
        ],
        others: [
          {
            url: 'https://medecinsdumonde.ch/contact/',
            text: 'Contact',
            external: true
          },
          {
            url: 'https://medecinsdumonde.ch/don/',
            text: 'Donation',
            external: true
          },
          {
            url: 'https://donateur.medecinsdumonde.org/mentions-legales',
            text: 'Mentions légales',
            external: true
          }
        ]
      }

      /**
       * If credits page exists, insert it between "Donation" and "Mentions légales"
       */
      const creditPageKey = findKey(state.data.data.pages, page => page.model === 'credits')
      if (creditPageKey) {
        nav.others.splice(2, 0, {
          url: `page/${creditPageKey}`,
          text: state.data.data.pages[creditPageKey].title,
          external: false
        })
      }

      return nav
    }
  })
}
</script>

<style lang="scss">
#app {
  .header__nav {
    padding: 0 !important;

    &-subheader {
      @include title(2, rgba($dark, 0.87));

      font-weight: 400;
      line-height: 1.5;
      letter-spacing: 0.18px;
      margin-top: 20px;
      margin-bottom: 10px;

      + .header__nav-list {
        padding-top: 0;
      }
    }

    &-list {
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 0 !important;
      border-bottom: 1px solid rgba($dark, 0.12);

      &:last-child {
        border: none;
        padding-bottom: 0;
      }

      &.main {
        .header__nav {
          &-list {
            &-item {
              display: flex;
              align-items: center;
              margin-bottom: 20px !important;

              &:last-child {
                margin-bottom: 0 !important;
              }

              .v-list-item__icon {
                display: inline-flex;
                align-self: center;
                font-size: 1.5rem;
                line-height: 1em;
                width: 1.5rem;
                height: 1.5rem;
                margin-top: 0;
                margin-bottom: 0;
                margin-right: 20px;
              }

              .v-list-item__content {
                padding: 0;
              }

              &-link {
                @include paragraph('link', rgba($dark, 0.87));

                display: inline-flex;
                align-items: center;

                .icon {
                  margin-left: 0;
                }
              }
            }
          }
        }
      }

      &-item {
        padding: 0 !important;
        margin-bottom: 10px !important;
        min-height: auto !important;
        cursor: default;

        .v-ripple__container {
          display: none;
        }

        &::before,
        &::after {
          content: none;
        }

        &:last-child {
          margin-bottom: 0 !important;
        }

        &-link {
          @include paragraph('small', rgba($dark, 0.6));

          display: inline-flex;
          align-items: center;

          .icon {
            margin-left: 8px;
          }
        }
      }
    }
  }

  .header[theme="pediatrie"] {
    .header__nav {
      &-subheader {
        color: rgba($purple-dark, 0.9);
      }

      &-list {
        &.main {
          .header__nav {
            &-list {
              &-item {
                &-link {
                  &.adulte {
                    &:hover {
                      color: $blue;
                    }
                  }

                  &.pediatrie {
                    &:hover {
                      color: $pink;
                    }
                  }
                }
              }
            }
          }
        }

        &-item {
          &-link {
            color: rgba($purple-dark, 0.6);
            transition: color 0.3s ease-in-out;

            &:hover {
              color: rgba($purple-dark, 1);
            }
          }
        }
      }
    }
  }

  .header[theme="adulte"] {
    .header__nav {
      &-subheader {
        color: rgba($blue-dark, 0.9);
      }

      &-list {
        &.main {
          .header__nav {
            &-list {
              &-item {
                &-link {
                  &.adulte {
                    &:hover {
                      color: $blue;
                    }
                  }

                  &.pediatrie {
                    &:hover {
                      color: $pink;
                    }
                  }
                }
              }
            }
          }
        }

        &-item {
          &-link {
            color: rgba($blue-dark, 0.6);
            transition: color 0.3s ease-in-out;

            &:hover {
              color: rgba($blue-dark, 1);
            }
          }
        }
      }
    }
  }
}
</style>
