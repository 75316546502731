import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import colors from '../utils/colors'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        purple: colors.purple.base,
        'purple-dark': colors.purple.dark,
        pink: colors.pink.base,
        'blue-light': colors.blue.light,
        blue: colors.blue.base,
        'blue-dark': colors.blue.dark,
        green: colors.green.base,
        grey: colors.grey.base,
        'grey-light': colors.grey.light,
        'grey-medium': colors.grey.medium,
        dark: colors.grey.dark
      }
    }
  }
})
